body{
    /* background-color: #f8fffb; */
    background-color: #fff;

}
.cursor-pointer{
  cursor: pointer;
}
.btn-close{
  position: absolute;
  right: 16px;
  bottom: 10px;
}
.navbar{
    background-color: transparent !important;
}
.nav-item a{
    margin: 0 0.6rem;
    color: #214A46;    
}
@media (min-width: 992px) {
    #navbarNav{
      flex-grow: unset;
   }
}
.center-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.mt-6{
  margin-top: 12rem;
}
.mt-7{
  margin-top: 8rem;
}
h1{
    font-family: 'Outfit';
    font-weight: 600;
    color: #214A46;
    font-size: 4rem;
}
body{
    font-family: 'Onest';

}
p,li,a,input{
    font-family: 'Onest';
}

.btn-rnd{
    border-radius: 500px;
    background:#3CBD96 ;
    color: #fff;

}
.btn-smth{
    border-radius: 5px;
    background:#3CBD96 ;
    color: #fff;

}
.btn-stroked{
    border: 1px solid #3CBD96;
    border-radius: 6px;
    color: #3CBD96;

}

.fw-300 {
    font-weight: 300;
  }
  
  .fw-400 {
    font-weight: 400;
  }
  
  .fw-500 {
    font-weight: 500;
  }
  
  .fw-600 {
    font-weight: 600;
  }
  
  .fw-700 {
    font-weight: 700;
  }
  
  .fw-800 {
    font-weight: 800;
  }
  
  .fw-900 {
    font-weight: 900;
  }

  .custom-select {
    appearance: none; /* Hide default arrow */
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #f8f8f8;
    padding-right: 30px; /* Add padding to make space for the custom arrow */
    position: relative;
}

.custom-select::after {
    content: '▼'; /* Custom arrow character */
    position: absolute;
    top: 50%;
    right: 10px; /* Position arrow to the right */
    transform: translateY(-50%);
    pointer-events: none; /* Ensure the arrow doesn’t interfere with the dropdown */
    color: #333;
}


/* Progress Bar CSS */
.hh-grayBox {
	background-color: #F8F8F8;
	margin-bottom: 20px;
	padding: 35px;
  margin-top: 20px;
}
.pt45{padding-top:45px;}
.order-tracking{
	text-align: center;
	width: 25%;
	position: relative;
	display: block;
}
.order-tracking .is-complete{
	display: block;
	position: relative;
	border-radius: 50%;
	height: 30px;
	width: 30px;
	border: 0px solid #AFAFAF;
	background-color: #f7be16;
	margin: 0 auto;
	transition: background 0.25s linear;
	-webkit-transition: background 0.25s linear;
	z-index: 2;
}
.order-tracking .is-complete:after {
	display: block;
	position: absolute;
	content: '';
	height: 14px;
	width: 7px;
	top: -2px;
	bottom: 0;
	left: 5px;
	margin: auto 0;
	border: 0px solid #AFAFAF;
	border-width: 0px 2px 2px 0;
	transform: rotate(45deg);
	opacity: 0;
}
.order-tracking.completed .is-complete{
	border-color: #27aa80;
	border-width: 0px;
	background-color: #27aa80;
}
.order-tracking.completed .is-complete:after {
	border-color: #fff;
	border-width: 0px 3px 3px 0;
	width: 7px;
	left: 11px;
	opacity: 1;
}
.order-tracking p {
	color: #A4A4A4;
	font-size: 16px;
	margin-top: 8px;
	margin-bottom: 0;
	line-height: 20px;
}
.order-tracking p span{font-size: 14px;}
.order-tracking.completed p{color: #000;}
.order-tracking::before {
	content: '';
	display: block;
	height: 3px;
	width: calc(100% - 40px);
	background-color: #f7be16;
	top: 13px;
	position: absolute;
	left: calc(-50% + 20px);
	z-index: 0;
}
.order-tracking:first-child:before{display: none;}
.order-tracking.completed:before{background-color: #27aa80;}
