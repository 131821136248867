@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Onest:wght@100..900&family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Onest:wght@100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Outfit:wght@100..900&display=swap');


/************ STARTS || Home Page CSS  *************/

.for-phone{
  display: none !important;
}

.hero-section {
  height: 100vh;
  /* background: url('assets/CourierWorld-hero.svg'); */
  /* background-position:;  */
  /* background-repeat: no-repeat;
  background-size: contain; */
  display: flex;
  justify-content: center;
}

.hero-animation {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -100;
  background: linear-gradient(180deg, #f2fef7, #f8fffb);

}
.logo-cntner img {
  width: 100%;
}

.logo-cntner {
  width: 100px;
  margin-right: 70px;
}
.hero-contents {
  max-width: 800px;
}

.home-cards .card {
  border: 1px solid #214A46;
  padding: 2rem 0rem;
}

.home-cards {
  color: #214A46;
}

.home-cards h5 {
  margin-top: 1rem;
  color: #214A46;
}

.home-cards .card img {
  width: 7rem;
}

.home-cal-quote-container {
  background-color: #E3F8E7;
}

.prom-num {
  color: #c1eadc;
  font-weight: 800;
  font-size: 1.5rem;
  margin-right: 10px;
}

/* .hero-image-container{
  width: 100%;
}
.hero-image-container img{
  width: 100%; 
} */

/************ ENDS || Home Page CSS  *************/


/************ STARTS || Quote Form CSS  *************/
.cal-quote-form {
  background-color: white;

}

.cal-quote-form .tab-pane {
  padding: 30px;
  border: 1px solid #ddd;
  border-radius: 0px 10px 10px 10px;
}

.quote-field {
  display: flex;
  flex-direction: column;
}

.inp-y-spc {
  margin: 1rem 0;
}

.cal-quote-form label,
.anything-dangerous-fields p {
  color: #214A46;
  font-weight: 500;
  margin: 0.6rem 0;
}

.cal-quote-form input {
  border: 1px solid #ccc;
  background-color: #fafafa;
  padding: 10px 10px;
  border-radius: 3px;
}

.cal-quote-form input::placeholder {
  color: #5A5858;
}

.anything-dangerous-fields .radio-optn-cntnr {
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.anything-dangerous-fields label {
  margin-left: 0.3rem;
}

.dimension-fields div input {
  /* max-width: 150px; */
  width: 100%;
}

.dimension-fields span {
  font-size: 0.9rem;
  color: #5A5858;
  margin-left: 1rem;
}

.radio-optn-cntnr input,
label {
  cursor: pointer;
}

select {
  width: 100%;
  /* max-width: 150px; */
  border: 1px solid #ccc;
  background-color: #fafafa;
  padding: 10px 10px;
  border-radius: 3px;
}

.addr-suggestions {
  border: 1px solid #ccc;
  position: absolute;
  width: 100%;
  top: 88px;

}

.addr-suggestions span {
  display: block;
  border-bottom: 1px solid #ccc;
  background-color: #f9f9f9;
  padding: 10px 10px;
  color: #3e3e3e;
}

.lifts-btn {
  border: 1px solid #214A46;
  border-radius: 2px;
  padding: 5px 8px;
  margin-right: 1.5rem;
  margin-top: 16px;
  cursor: pointer;
}

.lifts-btn img {
  width: 30px;
  margin-right: 10px;

}

/************ ENDS || Quote Form CSS  *************/



/************ STARTS || Displaying Quotes CSS  *************/

.quote-detail-sidebar {
  position: sticky;
  top: 10px;
}

.quote-container .quote {
  border: 1px solid #ccc;
  background: white;
  padding: 1rem 2rem;
  border-radius: 6px;
  margin: 1rem 0px;
}

.quote-container .quote p {
  margin: 1rem 0px;
}

.quote-container .quote .quote-ident {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  font-weight: 500;
}

.quote-details img {
  margin-right: 8px;
}

.quote-details div {
  margin: 0.8rem 0;
  font-weight: 400;
}

.quote .price {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.quote .price div>div {
  margin: 10px 10px;
}

.quote .price .amount {
  font-size: 1.8rem;
  font-weight: 500;
  font-family: "Open Sans", serif;

}

.quote .price .tax-tag {
  font-size: 0.8rem;
  margin-left: 1rem;
}

.quote-detail-sidebar {
  border: 1px solid #ccc;
  background-color: white;
  padding: 2rem 1.5rem;
  border-radius: 6px;
}

.loctions-info div span {
  font-size: 1.1rem;
}

.loctions-info div img {
  width: 15px;
  margin-right: 8px;
}

.loctions-info p {
  font-size: .8rem;
  color: #214A46;

}

.weight-info img {
  margin-right: 0.5rem;
  width: 18px;
}

/* quote-detail-sidebar-spacing */
.qds-spacing {
  margin-bottom: 1.2rem;
}

.quote-detail-sidebar {
  margin: 1rem 0;
}

.border-red {
  border: 1px solid red !important;
}

.sidebar-carrier-Details {
  color: #214A46;
}

.sidebar-carrier-Details .sc-price {
  font-family: "Open Sans", serif;

}

.sidebar-carrier-Details .sc-logo {
  width: 50px;
  margin-left: 1.2rem;
}

.sidebar-carrier-Details .sc-logo img {
  width: 6rem;
}

/************ ENDS || Displaying Quotes CSS  *************/



/************ STARTS || SRdetails CSS  *************/


.sr-details-container {
  background: white;
  padding: 30px;
  border: 1px solid #ddd;
  border-radius: 0px 0px 5px 5px;

}

.sr-details-container input {
  border: 1px solid #ccc;
  background-color: #fafafa;
  padding: 10px 10px;
  border-radius: 3px;
  max-width: 100%;
}

.sr-details-container select {
  width: 100%;
  max-width: 228px;

}

.sr-details-container::placeholder {
  color: #5A5858;
}

.sender-type {
  margin-left: 2rem;
}

.cesseninfo {
  background-color: #3CBD96;
  color: white;
  border-radius: 5px 5px 0px 0px;
}

.cesseninfo img {
  margin-right: .4rem;
}

/************ ENDS || SRdetails CSS  *************/




/************ STARTS || Review & Pay CSS  *************/



.details-container {
  border: 1px solid #ccc;
  background: white;
  padding: 1.1rem 2rem;
  border-radius: 6px;
  margin: 1rem 0px;
  color: #214A46;
}

.details-container p {
  margin-bottom: 0.5rem;
}

.cesseninfo-review {
  background-color: white !important;
}

.cesseninfo-review span {
  color: #214A46;
}

.mr-spc {
  margin-right: 1.5rem;
}


/************ ENDS || Review & Pay CSS  *************/



/************ STARTS || Tracking Page CSS  *************/

.tracking-input-container {
  max-width: 500px;
}

.tracking-input-container input {
  border: 1px solid #214A46;
  border-radius: 2px;
  padding: .7rem;
}

.tracking-input-container button {
  border-radius: 2px 2px 2px 2px;
  background-color: #214A46;
  color: white;
  padding: .7rem 2rem;

}

/************ ENDS || Tracking Page CSS  *************/


/************ STARTS || Bookings CSS  *************/

.booked-booking {
  background-color: #f2fef7;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #214A46;

}

.bkng-main-info {
  font-size: 1.1rem;
  font-family: "Open Sans", serif;
  ;
}

.bkng-main-info .bkng-status {
  background: #214A46;
  color: white;
  border-radius: 500px;
  padding: 5px 15px;
  font-size: .9rem;
}

.bkng-courier img {
  width: 4rem;
}

.bkng-main-info .bkng-Price {
  font-size: 1.2rem;
  font-weight: 600;
}

.bkng-info img {
  margin-right: 8px;
}

/************ ENDS || Bookings CSS  *************/


/************ STARTS || EDIT MODAL CSS  *************/

.modal-body .sender-form input {
  border: 1px solid #ccc;
  background-color: #fafafa;
  padding: 10px 10px;
  border-radius: 3px;
  max-width: 100%;
}

.modal-body .sender-form::placeholder {
  color: #5A5858;
}

.account-signup {
  border: 1px solid #ccc;
  padding: 1.1rem 2rem;

}

.acc-reg input {
  border: 1px solid #ccc;
  background-color: #fafafa;
  padding: 10px 10px;
  border-radius: 3px;
  max-width: 100%;
}

/************ ENDS || EDIT MODAL CSS  *************/



/************ STARTS || Admin Dashobard CSS  *************/

.dash-data-box {
  border: 1px solid #ccc;
  padding: 1rem 2rem;
}

.dash-table {
  border: 1px solid #ccc;
}

.table-container {
  padding: 20px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.markup-input {
  width: 100%;
  margin-right: 1rem;
  max-width: 140px;
}

.dash-boxes-row .col-lg-3 {
  height: 100% !important;
}

/************ ENDS || Admin Dashobard CSS  *************/


/************ STARTS || Admin Login CSS  *************/

.admin-login-container h2 {
  color: #214A46;
}

.admin-login-container button {
  background: #214A46;
}


/************ STARTS || Tracking Module CSS  *************/

.booking-shipment-module {
  width: 100%;
  max-width: 750px;
}

.bkng-progress-bar {
  display: flex;
  justify-content: center;
  align-items: center;

}

.bkng-progress-bar table {
  width: 800px;
  border: 1px solid #ccc;
}

.bkng-progress-bar th,
.bkng-progress-bar td {
  background: transparent !important;
  color: #214A46 !important;
}

/************ ENDS || Tracking Module CSS  *************/

/************ STARTS || Login CSS  *************/

.login-container {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 400px;
  padding: 2rem;
}

.login-container input {
  border: 1px solid #ccc;
  background-color: #fafafa;
  padding: 10px 10px;
  border-radius: 3px;
  margin-top: 1rem;
  width: 100%;
}

.login-wrapper {
  height: 80vh;
}

.forget-pass-link {
  text-decoration: underline;
  color: #214A46;
  cursor: pointer;
}

/************ ENDS || Login CSS  *************/



/************ STARTS || Footer CSS  *************/

footer {
  border-radius: 50px 50px 0px 0px;
  z-index: 100;
  /* top: -80px; */
  background-color: #3CBD96;
}

footer a {
  color: white;
  display: block;
}

footer .social-links .social-icon {
  width: 2rem;
}

footer .social-links .social-icon img {
  width: 100%;
}

.copyright-text-container {
  border-top: .5px solid white;
  background-color: #3CBD96;

}

/************ ENDS || Footer CSS  *************/


@media screen and (max-width: 992px) {
  .dvan-container img {
    width: 100%;
  }

  .dvan-container {
    position: relative;
    top: 0;
    right: 0px;
  }

  /* starts || CalcQuote Responsive CSS */
  .dim-fields #length {
    margin-top: 0rem;
  }

  .dim-fields input {
    margin-top: 1.5rem;
  }

  .sr-details-container input {
    margin-top: 1rem;
    width: 100%;
  }

  .sr-details-container .mt-4 {
    margin-top: 0 !important;
  }

  .sr-details-container button {
    margin-top: 2rem !important;
  }

  .sender-form {
    margin-top: 0 !important;
  }

  .sCourierInfo {
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start !important;
    padding: 0 30px;
  }

  .sCourierInfo>div {
    margin-bottom: 10px;
  }

  .col-rev-992 {
    display: flex;
    flex-direction: column-reverse;
  }

  /* Ends || CalcQuote Responsive CSS */


  /* starts ||Review Responsive CSS */

  .cesseninfo-review>div {
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start !important;
    padding: 0 0px;
  }

  .cesseninfo-review>div>div {
    margin-bottom: 10px;
  }

  /* Ends ||Review Responsive CSS */

  /* starts ||Bookings Responsive CSS */

  .bkng-main-info>span {
    margin-top: 1rem;
  }

  /* Ends ||Bookings Responsive CSS */


  .for-desktop{
    display: none !important;
  }
  .for-phone{
    display: block !important;
  }
}

@media screen and (max-width: 520px) {
  h1 {
    font-size: 3rem !important;
  }

  .hero-animation {
    min-height: 120vh !important;
  }
  .home-cards{
    display: none !important;
  }
  .hero-section {
    height: 70vh !important;
  }
  .mt-6 {
    margin-top: 4rem !important;
  }
}



/************ STARTS || Ticker CSS  *************/

.logos-ticker {
  font-size: 15px;
  padding-block: 8px;
  overflow: hidden;
  user-select: none;

  --gap: 20px;
  display: flex;
  gap: var(--gap);
}

.logos-ticker ul {
  list-style: none;
  flex-shrink: 0;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--gap);

  animation: scroll 20s linear infinite;
}


@keyframes scroll {
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

/************ ENDS || Ticker CSS  *************/
